import api from './api';

class DivisionService {
  getDivisions() {
    return api.get('secure/team/divisions');
  }

  getDivisionsTeams() {
    return api.get('secure/team/teamsbydiv');
  }

}

export default new DivisionService();